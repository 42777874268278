html, body, #root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

.Art {
  background-color: black;
  color: black;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Art-board-container {
  position: absolute;
  background-color: white;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  top: 0px;
  -webkit-transition: .5s;
  transition: .5s;
  z-index: 1;
}
.Art-board {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0px 20px;
}

.Art-title h3 {
  font-weight: 200;
}
.Art-link {
  color: black;
  text-decoration: none;
}
.Art-link-disabled {
  color: gray;
}
.Art-link:hover {
  text-decoration: underline;
}

.Home {
  min-height: 200vh;
  width: 100vw;
}
.Home-position {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  width: 100vw;
}

.FlipCard-wrapper {
  -webkit-align-items: center;
          align-items: center;
  background-image: -webkit-linear-gradient(top left, black, white);
  background-image: linear-gradient(to bottom right, black, white);
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  width: 100vw;
}
.FlipCard-container {
  position: relative;
  width: 550px;
  height: 300px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.FlipCard-front {
  font-size: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}
.FlipCard-back {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
}

.FlipCard-title {
  font-size: 36px;
}
.FlipCard-title a {
  color: white;
  text-decoration: none;
}
.FlipCard-title a:hover {
  text-decoration: underline;
}
.FlipCard-body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.FlipCard-header-item {
  font-weight: 700;
}
.FlipCard-item:hover {
  text-decoration: underline;
}
.FlipCard-item a {
  font-weight: 200;
  text-decoration: none;
  color: white;
}

.FlipCard-section {
  padding-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.FlipCard {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background-color: black;
  box-sizing: border-box;
  color: white;
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100%;
  padding: 20px;
  position: absolute;
  width: 100%;
}

.LightCycle {
  -webkit-align-items: center;
          align-items: center;
  color: white;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}
.LightCycle-title {
  font-size: 24px;
  margin: 0px 20px;
  text-align: center;
}
.LightCycle-loading {
  -webkit-align-items: center;
          align-items: center;
  color: gray;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}
.LightCycle-error {
  -webkit-align-items: center;
          align-items: center;
  color: red;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.Reel {
	height: 100%;
	text-align: center;
	background-image: url('https://source.unsplash.com/random');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}
.Reel img {
	width: 100%;
}
.Reel-refresh {
	display: inline-block;
	margin: 10px;
	color: white;
	cursor: pointer;
}

.Reel-refresh:hover {
	text-decoration: underline;
}
.Writings {
    position: absolute;
    background-color: white;
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.Writings-list {
    text-align: left;
}
.Writing {
    max-width: 500px;
    padding: 30px;
    font-weight: 300;
    font-family: 'Helvetica';
    line-height: 1.4;
}
