.Writings {
    position: absolute;
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Writings-list {
    text-align: left;
}