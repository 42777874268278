.LightCycle {
  align-items: center;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.LightCycle-title {
  font-size: 24px;
  margin: 0px 20px;
  text-align: center;
}
.LightCycle-loading {
  align-items: center;
  color: gray;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.LightCycle-error {
  align-items: center;
  color: red;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
