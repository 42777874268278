.Art {
  background-color: black;
  color: black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Art-board-container {
  position: absolute;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  transition: .5s;
  z-index: 1;
}
.Art-board {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
}

.Art-title h3 {
  font-weight: 200;
}
.Art-link {
  color: black;
  text-decoration: none;
}
.Art-link-disabled {
  color: gray;
}
.Art-link:hover {
  text-decoration: underline;
}
